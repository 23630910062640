globalThis.Convermax.getCategoryId = (item) => item.CategoryIDs?.[0] || 1;
globalThis.Convermax.quickview = (item) => (e) => {
  e.preventDefault();
  window.quickview(`/product.asp?lt_c=1&itemid=${item.CatalogID}&qv=1`);
};
globalThis.Convermax.isWithRedirect = (facet) =>
  window.document.body.classList.contains('category-page') && facet.field === 'Category' && facet.url;

export default {
  platform: 'shift4shop',
  searchPageUrl: '/search',
  SearchRequestDefaults: {
    pageSize: 36,
  },
  requestTimeout: 20000,
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: 'body.category-page .category-products',
      template: 'MainContent',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'Price': 'Price: Low to High',
        'Price:desc': 'Price: High to Low',
        'Name': 'Name',
        'DateCreated:desc': 'Newest',
      },
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'FacetPanel',
      fields: ['Category', 'Sale'],
      visibleIf: () => window.innerWidth > 991,
    },
    {
      name: 'AttributeFacetPanel',
      type: 'FacetPanel',
      ignoreFields: ['Category', 'Sale'],
      initCollapsed: true,
      visibleIf: () => window.innerWidth > 991,
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
    },
    {
      name: 'FacetPanelCategory',
      type: 'SearchPage',
      location: 'body.category-page #leftBar .sidebar-widget',
      template: 'FacetPanelContainer',
    },
    {
      name: 'SearchBox',
      location: {
        replace: '.site-header #searchBox:not(.searchBoxID)',
        class: 'searchBox',
      },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.cm_mobile-search',
        class: 'cm_search-box-dialog-button_container col-xs-3 visible-xs text-center',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
  ],
};
