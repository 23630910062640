//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-784:_9380,_3968,_2653,_7480,_6516,_2796,_6148,_1952;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-784')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-784', "_9380,_3968,_2653,_7480,_6516,_2796,_6148,_1952");
        }
      }catch (ex) {
        console.error(ex);
      }